import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'
import { includes } from 'lodash';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from 'react-accessible-accordion'

import '../../../node_modules/react-accessible-accordion/dist/fancy-example.css'
import CatalogMembers from './CatalogMembers';

const main = css({
  'label': 'refCatalog',

  '.accordion__title': {
    color: '#fff !important'
  },

  '.categories': {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'row wrap',
  },

  '.category': {
    width: 'calc(100% / 2 - 0.4em)',
    padding: '1.5em',
    margin: '0.2em',
    border: '1px solid #937EB6',
    transition: 'all 0.3s ease',
    wordBreak: 'break-word',

    ':hover:not(.service)': {
      background: '#937EB6',
      color: '#fff'
    },

    ':hover:not(.product)': {
      background: '#C7EBBC'
    },

    span: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'normal'
    }
  },

  '.active-product': {
    background: '#6039B5',
    color: '#fff'
  },

  '.active-service': {
    background: '#72CE55',
    color: '#fff'
  },

  h1: {
    letterSpacing: '3px',
    textDecorationSkipInk: 'none',
    textUnderlinePosition: 'under',
    '&.product': {
      textDecoration: 'underline solid #6039B5',
    },
    '&.service': {
      textDecoration: 'underline solid #73CE57',
    }
  },

  h4: {
    color: '#fff !important'
  },

  '.ref': {
    padding: '3rem 0',
    fontWeight: 'bold',
    margin: '0.5rem 0 1rem 0',
    background: '#937EB6',
    color: '#fff',

    p: {
      padding: '0 0.2em'
    }
  },

  '.accordion': {
    borderTop: 0
  },

  '@keyframes blink': {
    '0%': { opacity: 0.2 },
    '15%': { opacity: 0.3 },
    '35%': { opacity: 0.5 },
    '45%': { opacity: 0.6 },
    '65%': { opacity: 0.8 },
    '85%': { opacity: 0.9 },
    '99%': { opacity: 1 }
  },

  '.blink': {
    animationName: 'blink',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    animationDirection: 'alternate',
  },

  '@media all and (max-width: 450px)': {
    '.category': {
      width: '100%'
    }
  }
});


const RefCatalog = ({ allMembers, allProducts, allServices, prevProduct, prevService }) => {

  const [selectedProduct, setSelectedProduct] = useState(prevProduct);
  const [selectedService, setSelectedService] = useState(prevService);
  const [selectedMembers, setSelectedMembers] = useState([]);

  useEffect(() => {
    filterMembers({ selectedProduct: prevProduct, selectedService: prevService });
  }, []);

  const renderTitle = () => {
    return !selectedMembers.length ? <h3 className="has-text-centered">{selectedService || selectedProduct ? `No hay empresarias ofreciendo el Servicio o Producto seleccionado` : `Seleccione un Producto o Servicio`}</h3> : <h1 className={`has-text-centered ${selectedProduct ? 'product' : 'service'}`}>{(selectedProduct && selectedProduct.toUpperCase()) || (selectedService && selectedService.toUpperCase())}</h1>
  }

  const selectProduct = name => {
    setSelectedProduct(name);
    setSelectedService('');
    filterMembers({ selectedProduct: name });
    window && window.history.pushState({ selectedProduct: name }, '');
  }

  const selectService = name => {
    setSelectedService(name);
    setSelectedProduct('');
    filterMembers({ selectedService: name });
    window && window.history.pushState({ selectedService: name }, '');
  }

  const filterMembers = ({ selectedService, selectedProduct }) => {
    const newSelectedMembers = allMembers.filter(({ node }) =>
      (selectedProduct && includes(node.selectedProducts, selectedProduct)) || (selectedService && includes(node.selectedServices, selectedService)));

    setSelectedMembers(newSelectedMembers.map(member => member.node));
  }

  return (
    <div className={main}>

      <Accordion allowMultipleExpanded={true}>
        <div className="columns">
          <div className="column">
            <AccordionItem>
              <AccordionItemHeading style={{ background: '#6039B5', color: '#fff' }}>
                <AccordionItemButton style={{ textAlign: "center" }}>
                  Productos
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="categories has-text-centered">
                  {
                    allProducts.sort().map((p, i) => <div key={i} className={`button category product ${selectedProduct === p ? 'active-product' : ''}`} onClick={() => selectProduct(p)}><span>{p.toUpperCase()}</span></div>)
                  }
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </div>
          <div className="column">
            <AccordionItem>
              <AccordionItemHeading style={{ backgroundColor: '#72CE55', color: '#fff' }}>
                <AccordionItemButton style={{ textAlign: "center" }}>
                  Servicios
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="categories">
                  {
                    allServices.sort().map((p, i) => <div key={i} className={`button category service ${selectedService === p ? 'active-service' : ''}`} onClick={() => selectService(p)}><span>{p.toUpperCase()}</span></div>)
                  }
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </div>
        </div>
      </Accordion>
      {renderTitle()}
      <CatalogMembers
        selectedMembers={selectedMembers}
        selectedService={selectedService} />
    </div>

  )

}

export default RefCatalog;