import React from 'react'
import { graphql, Link } from 'gatsby'
import { css } from 'emotion'

import Layout from '../../../components/Layout'
import SEO from '../../../components/SEO'
import RefCatalog from '../../../components/ref/RefCatalog'

import '../../../../node_modules/react-accessible-accordion/dist/fancy-example.css'

const main = css({
  'label': 'prodservices',

  '.ref': {
    padding: '3rem 0',
    fontWeight: 'bold',
    margin: '0.5rem 0 1rem 0',
    background: '#937EB6',
    color: '#fff',

    p: {
      padding: '0 0.2em'
    }
  }

});

export default class RedEconomica extends React.Component {
  constructor(props) {
    super(props);

    const { allMembers, allServices, allProducts } = props.data;

    const { selectedProduct, selectedService } = props.location.state || '';

    this.state = {
      allMembers: allMembers.edges,
      allServices: allServices.edges[0].node.name,
      allProducts: allProducts.edges[0].node.name,
      selectedProduct,
      selectedService
    }
  }

  render() {

    return (
      <Layout>
        <SEO
          isBlogPost={false}
          postData={{
            description: '¡Buscá productos y servicios provenientes de chicas feministas o forma parte de la red!',
            title: 'Red Económica Feminista',
            slug: '/red-economica-feminista/productosyservicios'
          }}
          postImage={'/img/ref-logo.jpg'}
        />
        <section className={main}>
          <div className="content">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                {
                  this.state.allMembers.length && <RefCatalog
                    allMembers={this.state.allMembers}
                    allProducts={this.state.allProducts}
                    allServices={this.state.allServices}
                    prevProduct={this.state.selectedProduct}
                    prevService={this.state.selectedService} />
                }
                <div className="column has-text-centered">
                  <Link className="button has-text-primary" to={'/red-economica-feminista'} rel="next">
                    {'< Regresar'}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
{
  allMembers(filter: { enabled: { eq: true } }) {
    edges {
      node {
        id
        name
        business_name
        business_name_lc
        enabled
        about_fem
        about_support
        categories
        selectedServices
        selectedProducts
        logo
        description
      } 
    }
  }
  allServices {
    edges {
      node {
        name
      }
    }
  }
  allProducts {
    edges {
      node {
        name
      }
    }
  }
}
`